import React, { useState } from 'react'
import { useStaticQuery, graphql} from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPdf = (isEn) => {

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    const pdfQuery = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "pdf"}}) {
        edges {
            node {
                name
                relativePath
                publicURL
            }
        }
        }
    }    

  `)

const pdfs = pdfQuery.allFile.edges.map((pdf) => pdf.node)
  
    return(
      <>
          <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
              <div className='flex flex-col mx-0 mt-20 bg-white/0 flex-wrap'>
                  <h2 className='text-center text-3xl font-bold'>{isEn == true? "CONGRESO INTERNACIONAL DE ATENCION CENTRADA EN LA PERSONA" : "CONGRESO INTERNACIONAL DE ATENCION CENTRADA EN LA PERSONA"}</h2>
              </div>  
            {/* <div className='flex flex-col xl:flex-row 2xl:mx-64 lg:mx-32 justify-center mx-0 my-20 bg-white/0 flex-wrap'>
              
              {
                images.map((image, index)=>(
                  <>
                    <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'>
                      <div key={index} >
                      <GatsbyImage image={getImage(image)} alt={image.name} />
                      <br />
                      <p className='font-bold text-xl mt-5'>{image.name}</p>
                      </div>
                    </div>
                  </>
                ))
              }
              
            </div> */}

            {
                pdfs.map((pdf, index)=>(
                  <>
                    {/* <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'> */}
                    <div>
                      <div key={index} >
                        {/* <Document file={pdf.publicURL} onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document> */}
                        <iframe class="w-full" src={pdf.publicURL} type="application/pdf" height="800"></iframe>
                      </div>
                    </div>
                  </>
                ))
              }
                <div className='flex justify-center my-10'>
                    <StaticImage
                        className='mt-24 w-[500px] flex justify-center items-center'
                        transformOptions={{ fit: "contain", cropFocus: "attention" }}
                        style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                        // maxHeight: 600,
                        }}
                        layout="fullWidth"
                        // You can optionally force an aspect ratio for the generated image
                        //aspectRatio={3 / 1}
                        // This is a presentational image, so the alt should be an empty string
                        alt="Congreso"
                        
                        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                        src={
                            "../images/index/congreso.jpg"
                        }
                        formats={["auto", "webp", "avif"]}
                    />
                </div>
              

          </div>
      </>
    )
  }
  
  export default IndexPdf
